export default {
    en: {
        amounts: [
                "1 time",
                "2 times",
                "3 times",
                "4 times",
                "5 times",
                "6 times",
                "7 times",
                "8 times",
                "9 times",
                "10 times",
        ],
        intervals: [
            "Week",
            "Month",
        ]
    },
    de: {
        amounts: [
            "1 mal",
            "2 mal",
            "3 mal",
            "4 mal",
            "5 mal",
            "6 mal",
            "7 mal",
            "8 mal",
            "9 mal",
            "10 mal",
        ],
        intervals: [
            "Woche",
            "Monat",
        ]
    }
}