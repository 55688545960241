/*
In this file you find all methods for the server communication
*/

import db from "./init.js"
import store from "../store/index.js";

//creates a reminders
export async function sendReminderToServer(reminder) {
    const BASE_URL = "https://squaregarden-backend.sharky.live/api/v1/reminder";
    const devURL = "http://localhost:24711/api/v1/reminder"
    const options = {
        method: "POST",
        body: JSON.stringify(reminder),
        headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    }
    /*
    The fetching
    */
    try{
        const response = await fetch(devURL, options)
        console.log(response)
        if(response.status == 200 || response.status == 201 || response.status == 204) {
            return {status: 200, message: "Your reminder got added!"}
        } else if(response.status == 429) {
            return {status: 429, message: "Your reminder could not get added, try again in 15 minutes!"}
        } else { 
            return {status: 401, message: "An error accured!"}
        }
    } catch(err) {
        return {status: 400, message: "An error accured, make sure you have an internetconnection!"}
    }
}