<template>
    <div class="sidebar-page">
        <section class="sidebar-layout">
             <b-sidebar
                position="fixed"
                type="is-light"
                :fullheight="fullheight"
                :left="left"
                :open="showMenu"
                :overlay="overlay"
                :can-cancel="can_cancel"
                :on-cancel="toggleMenu"
            >
                <div class="p-1">
                    <div class="block">
                        <h2 class="heading">Square<span>Garden</span></h2>
                    </div>
                    <b-menu>
                        <b-menu-list :label="`${$t('garden.menu.menu.heading')}`">
                            <p class="p-1">{{ $t("garden.menu.menu.current") }}: <b>{{ currentSpec }}</b></p>
                            <p class="p-1">{{ $t("garden.menu.menu.plantSpec") }}</p>
                            <div class="control has-icons-left" v-if="currentType == 'fruit'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in fruits"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <p class="icn">🍓</p>
                                </div>
                            </div>
                            <!--<b-select v-if="currentType == 'fruit'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in fruits"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>-->
                            <div class="control has-icons-left" v-if="currentType == 'herb'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in herbs"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <p class="icn">🌿</p>
                                </div>
                            </div>
                            <!--
                            <b-select v-if="currentType == 'herb'"  native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in herbs"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>-->
                            <div class="control has-icons-left" v-if="currentType == 'flower'">
                                <div class="select is-success is-normal" >
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in flowers"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div>
                                    <div class="icon is-small is-left">
                                        <p class="icn">🌺</p>
                                    </div>
                                </div>
                            </div>
                            
                            <!--
                            <b-select v-if="currentType == 'flower'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in flowers"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>-->
                            <div class="control has-icons-left" v-if="currentType == 'tree'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in trees"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <p class="icn">🌲</p>
                                </div>
                            </div>
                            <!--
                            <b-select v-if="currentType == 'tree'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in trees"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>-->
                            <div class="control has-icons-left" v-if="currentType == 'vegetable'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in vegetables"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="is-small is-left icon">
                                    <p class="icn">🥕</p>
                                </div>
                            </div>
                            
                            <!--
                            <b-select v-if="currentType == 'vegetable'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in vegetables"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>
                            -->
                            <div class="control has-icons-left" v-if="currentType == 'succulent'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in succulents"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <p class="icn">🌵</p>
                                </div>
                            </div>
                            
                            <!--
                            <b-select v-if="currentType == 'succulent'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in succulents"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>
                            -->
                            <div class="control has-icons-left" v-if="currentType == 'bush'">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.menu.placeholder')}`" name="type" id="specSelector" v-model="selectedSpec">
                                        <option
                                            v-for="(elem, index) in bushes"
                                            :value="elem"
                                            :key="index"
                                        >
                                            {{ elem }}
                                        </option>
                                        <option value="others">{{ $t("garden.menu.menu.others")}}</option>
                                    </select>
                                </div>
                                <div class="is-left is-small icon">
                                    <p class="icn">🌳</p>
                                </div>
                            </div>
                            <!-- element for others -->
                            <div v-if="selectedSpec == 'others'" class="p-1">
                                <div class="field">
                                    <label for="otherSpec" class="label">
                                        {{ $t("garden.menu.menu.otherSpec") }}
                                    </label>
                                    <div class="control">
                                        <input class="input is-success is-normal" type="text" placeholder="" v-model="otherSpec" name="otherSpec">
                                    </div>
                                </div>
                                    
                            </div>
                            <!--
                            <b-select v-if="currentType == 'bush'" native-size="8" placeholder="Select a type" name="type" expanded id="specSelector" v-model="selectedSpec" multiple>
                                <option
                                        v-for="(elem, index) in bushes"
                                        :value="elem"
                                        :key="index"
                                >
                                    {{ elem }}
                                </option>
                            </b-select>-->
                            <!-- TODO:
                            <b-menu-item label="Wikipedia Informationen zu dieser Pflanze und Link zu Wikipedia" icon="cellphone-link"></b-menu-item>
                            -->
                        </b-menu-list>
                        <b-menu-list :label="`${$t('garden.menu.fertilizer.heading')}`">
                            <p class="p-1">{{ $t("garden.menu.fertilizer.current") }}: <b>{{ currentFertilizer }}</b></p>
                            <p class="p-1">{{ $t("garden.menu.fertilizer.plantSpec") }}</p>
                            <div class="control has-icons-left">
                                <div class="select is-success is-normal">
                                    <select :placeholder="`${$t('garden.menu.fertilizer.placeholder')}`" name="type" expanded id="fertilizer" v-model="selectedFertilizer">
                                        <option
                                            v-for="fertilizer in fertilizers"
                                            :value="fertilizer.data.name"
                                            :key="fertilizer.id"
                                        >
                                            {{ fertilizer.data.name }}
                                        </option>
                                        <option value="">{{ $t("garden.menu.fertilizer.noFertilizer") }}</option>
                                    </select>
                                </div>
                                <div class="icon is-small is-left">
                                    <p class="icn">🛢️</p>
                                </div>
                            </div>
                            <div class="p-3">
                                <b-button
                                    @click="saveProperties"
                                    type="is-success"
                                    rounded
                                    expanded
                                >{{ $t("garden.menu.save.button") }}</b-button>
                            </div>
                        </b-menu-list>
                        <!-- TODO: -->
                        <!-- Button adding Fertilzer
                        <div class="p-2">
                            <b-button
                                type="is-success"
                                rounded
                                expanded
                                @click="addFertilizerModalActive = true"
                            >
                                <i class="fas fa-plus"></i>
                            </b-button>
                            <b-modal
                                v-model="addFertilizerModalActive"
                                has-modal-card
                                trap-focus
                                :destroy-on-hide="false"
                                aria-role="dialog"
                                aria-label="Add Fertilizer"
                                aria-modal>
                                <template #default="props">
                                    <modal-form v-bind="formProps" @close="props.close"></modal-form>
                                </template>
                            </b-modal>
                        </div> -->
                        <b-menu-list label="Reminder">
                                <div class="p-1">CurrentReminder:
                                    <span v-if="reminderForField">
                                        <div v-for="(reminder,index) in reminderForField" :key="index">
                                            <div class="card m-1 sm-block">
                                                <div class="">
                                                    <div v-if="reminder.data.amount" class="has-text-left">
                                                        <h5 class="mar"><span class="bold">Title:</span> {{reminder.data.title}}</h5>
                                                        <p class="mar"><span class="bold">Amount:</span> {{reminder.data.amount}} per {{reminder.data.interval}}</p>
                                                    </div>
                                                    <div v-else class="has-text-left">
                                                        <h5 class="mar"><span class="bold">Title:</span> {{reminder.data.title}}</h5>
                                                        <p class="mar"><span class="bold">Time:</span> 
                                                            <span v-for="(time, index) in reminder.data.times" :key="index">
                                                                {{ time }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <button class="button is-danger is-small center m-1" @click.prevent.self="deleteReminder" :id="reminder.id"><i class="fas fa-trash" @click.prevent.self="deleteReminder" :id="reminder.id"></i></button>
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else>No Reminder</span>
                                </div>
                            <div class="field p-1">
                                <label for="reminderTitle" class="label">
                                    {{ $t("garden.menu.reminder.title") }}
                                </label>
                                <div class="control">
                                    <input class="input is-normal is-success" type="text" :placeholder="`${$t('garden.menu.reminder.titlePlaceholder')}`" v-model="reminderTitle" name="reminderTitle">
                                </div>
                            </div>
                            <label class="label">{{ $t("garden.menu.reminder.reminderType") }}</label>
                            <b-select v-model="reminderType" name="amount" expanded id="reminderType" size="is-normal is-success">
                                <option value="daily">
                                    {{ $t("garden.menu.reminder.reminderTypeOptions.daily") }}
                                </option>
                                <option value="weekly">
                                    {{ $t("garden.menu.reminder.reminderTypeOptions.weekly") }}
                                </option>
                                <option value="monthly">
                                    {{ $t("garden.menu.reminder.reminderTypeOptions.monthly") }}
                                </option>
                            </b-select>
                            <div v-if="reminderType == 'daily'" class="p-1 center-only">
                                <div v-for="(time,index) in times" :key="index">
                                    <div class="block">
                                        <b-timepicker v-model="times[index]" inline class="mt" :incrementMinutes="minutesGranularity" size="is-small"></b-timepicker>
                                        <b-button
                                            @click="delTime(index)"
                                            type="is-danger is-small"
                                            class="mid"
                                        ><i class="fas fa-minus"></i></b-button>
                                    </div>
                                </div>
                                <b-button
                                    @click="addTime"
                                    type="is-warning is-small"
                                    class="mt"
                                ><i class="fas fa-plus"></i></b-button>
                            </div>
                            <div v-else-if="reminderType == 'weekly'">
                                <p class="p-1">{{ $t("garden.menu.reminder.remindAmount") }}</p>
                                <b-field :label="`${$t('garden.menu.reminder.amountPlaceholder')}`">
                                    <b-numberinput controls-alignment="left" size="is-small" type="is-success" v-model="reminderAmountWeekly"></b-numberinput>
                                </b-field>
                                <!--<b-select :placeholder="`${$t('garden.menu.reminder.amountPlaceholder')}`" name="amount" expanded id="reminderAmount">
                                    <option
                                        v-for="reminder in reminders"
                                        :value="reminder"
                                        :key="reminder"
                                    >
                                    <p>{{ reminder }}</p>
                                    </option>
                                </b-select> -->
                                <!-- 
                                <p class="center-only bolder">{{ $t("garden.menu.reminder.per") }}</p>
                                <b-select :placeholder="`${$t('garden.menu.reminder.intervalPlaceholder')}`" name="interval" expanded id="reminderInterval">
                                    <option
                                        v-for="interval in intervals"
                                        :value="interval"
                                        :key="interval"
                                    >
                                    {{interval}}
                                    </option>
                                </b-select> 
                                -->
                            </div>
                            <div v-else-if="reminderType == 'monthly'">
                                <p class="p-1">{{ $t("garden.menu.reminder.remindAmount") }}</p>
                                <b-field :label="`${$t('garden.menu.reminder.amountPlaceholder')}`">
                                    <b-numberinput controls-alignment="left" size="is-small" type="is-success" v-model="reminderAmountMonthly"></b-numberinput>
                                </b-field>
                            </div>
                        </b-menu-list>
                        <div class="p-3">
                            <b-button
                                @click.prevent="saveReminder"
                                type="is-success"
                                rounded
                                expanded
                            >{{ $t("garden.menu.save.button") }}</b-button>
                        </div>
                    </b-menu>
                </div>
            </b-sidebar>
        </section>
    </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import { getAllFertilizer, updateSquareSpecification, updateSquareFertilizer } from "../../db/dbMethods.js";
import { addReminder, getRemindersForUser, deleteReminderById } from "../../db/reminder.js";
import { sendReminderToServer } from "../../db/serverDB.js";

//importing lists
import remindersList from "@/multilang/application/lists/reminders.js"
import herbList from "@/db/data/lists/herb_list.js";
import fruitList from "@/db/data/lists/fruit_list.js";
import flowerList from "@/db/data/lists/flower_list.js";
import bushList from "@/db/data/lists/bush_list.js";
import succulentList from "@/db/data/lists/succulent_list.js";
import treeList from "@/db/data/lists/tree_list.js";
import vegetableList from "@/db/data/lists/vegetable_list.js";

export default {
    data() {
        return {
            //Data for the squaremenu
            fertilizers: [],
            specifications: [],

            left: true,
            fullheight: true,
            overlay: true,
            can_cancel: true,

            //for the wikipedia serach
            wikidata: {},

            //for adding fertilizer modal
            addFertilizerModalActive: false,
            //fromProps: {
            //},
            selectedSpec: null,
            selectedFertilizer: null,
            otherSpec: "",

            reminderTitle: "",
            reminderType: "",
            reminderAmountWeekly: 1,
            reminderAmountMonthly: 1,
            times: [new Date()],
            minutesGranularity: 15,
            savedReminder: null,
        }
    },
    components: {
       // ModalForm
    },
    methods: {
        ...mapMutations({
            toggleMenu: "SHOW_SQUARE_MENU",
        }),
        saveProperties() {
            //save the specification if some specification is selected
            if(this.selectedSpec){
                if(this.selectedSpec === "others"){
                    //Saving other name
                    updateSquareSpecification(this.currentId, this.otherSpec)
                } else {
                    //saving the specification for the Square
                    updateSquareSpecification(this.currentId, this.selectedSpec)
                }
            }
            //save the fertilizer if the fertilizer is selected
            if(this.selectedFertilizer || this.selectedFertilizer == ""){

                //saving the fertilizer for the square
                updateSquareFertilizer(this.currentId, this.selectedFertilizer);
            }
            //closing the SquareMenu 
            this.toggleMenu({type: null, fertilizer: null, specification: null, id:null});

            fertilizer.input = null;

            this.selectedSpec = null;
            this.selectedFertilizer = null;
        },
        async saveReminder() {
            if(this.reminderTitle){
                if(this.reminderType === "daily") {
                    const reminder = {
                        title: this.reminderTitle,
                        garden: false,
                        type: this.reminderType,
                        times: this.times,
                        parentId: this.currentId,
                        userId: this.$store.state.user.authStatus.uid,
                    };
                    try {
                        //Saving reminder on firebase storage
                        addReminder(reminder);
                        this.reminderTitle = "";
                        this.reminderType = "";
                        this.reminderAmountWeekly = 1;
                        this.reminderAmountMonthly = 1;
                        this.times = [new Date()];
                        //closing the SquareMenu 
                        this.toggleMenu({type: null, fertilizer: null, specification: null, id:null});
                        //Saving reminder on squaregarden backend
                        const res = await sendReminderToServer(reminder);
                        this.checkRes(res);
                    } catch (err) {
                        console.log("Error sending reminder to Server!!!");
                    }
                } else if(this.reminderType === "weekly") {
                    if(this.reminderAmountWeekly) {
                        //reminder values
                        const reminder = {
                            title: this.reminderTitle,
                            garden: false,
                            type: this.reminderType,
                            amount: this.reminderAmountWeekly,
                            interval: this.reminderType,
                            parentId: this.currentId,
                            userId: this.$store.state.user.authStatus.uid,
                        };
                        try {
                            //Saving reminder on firebase storage
                            addReminder(reminder);
                            this.reminderTitle = "";
                            this.reminderType = "";
                            this.reminderAmountWeekly = 1;
                            this.reminderAmountMonthly = 1;
                            this.times = [new Date()];
                            //closing the SquareMenu 
                            this.toggleMenu({type: null, fertilizer: null, specification: null, id:null});
                            //Saving reminder on squaregarden backend
                            const res = await sendReminderToServer(reminder);
                            this.checkRes(res);
                        } catch (err) {
                            console.log("Error sending reminder to Server!!!");
                        }
                    } else {
                        this.launchNotification("You need to select an amout and an Interval!")
                    }
                } else if(this.reminderType === "monthly") {
                    if(this.reminderAmountMonthly) {
                        //reminder values
                        const reminder = {
                            title: this.reminderTitle,
                            garden: false,
                            type: this.reminderType,
                            amount: this.reminderAmountMonthly,
                            interval: this.reminderType,
                            parentId: this.currentId,
                            userId: this.$store.state.user.authStatus.uid,
                        };
                        try {
                            addReminder(reminder);
                            this.reminderTitle = "";
                            this.reminderType = "";
                            this.reminderAmountWeekly = 1;
                            this.reminderAmountMonthly = 1;
                            this.times = [new Date()];
                            //closing the SquareMenu 
                            this.toggleMenu({type: null, fertilizer: null, specification: null, id:null});
                            //Saving reminder on squaregarden backend
                            const res = await sendReminderToServer(reminder);
                            this.checkRes(res);
                        } catch (err) {
                            console.log("Error sending reminder to Server!!!");
                        }
                    } else {
                        this.launchNotification("You need to select an amout and an Interval!")
                    }
                } else {
                    this.launchNotification("You need to specify a remindertype!")
                }
            } else {
                this.launchNotification("You need to specify a Reminder title!");
            }
        },
        async deleteReminder(e) {
            try {
                const res = await deleteReminderById(e.target.id);
                if(res.status === 0){
                    this.triggerSuccessAlert(res.message);
                    if(e.target.class="fas fa-trash"){
                        e.target.parentElement.parentElement.classList.add("hide");
                    } else {
                        e.target.parentElement.classList.add("hide");
                    }
                } else if(res.status === 1){
                    this.triggerFailAlert(res.message)
                }
            } catch (err) {
                this.triggerFailAlert(err)
            }
        },
        addFertilizer(){
                this.$buefy.dialog.prompt({
                message: `Add a Fertilizer to your Account 🐛`,
                inputAttrs: {
                    type: 'text',
                    placeholder: 'Name...',
                    value: '',
   
                },
                confirmText: "Add",
                trapFocus: true,
                closeOnConfirm: true,
                onConfirm: (name) => {
                    this.$buefy.toast.open(`Fertilizer added!`);
                    this.saveFertilizer(name);
                }
            })
        },
        addTime() {
            this.times.push(new Date());
        },
        delTime(index) {
            console.log(index)
            this.times.splice(index,1);
        },
        launchNotification(message) {
                this.$buefy.notification.open({
                    duration: 1500,
                    message: message,
                    position: 'is-top',
                    type: 'is-danger',
                    hasIcon: true
                })
                //notif.$on('close', () => {
                //    this.$buefy.notification.open('')
                //})
        },
        checkRes(res){
            if(res.status === 200){
                this.triggerSuccessAlert(res.message);
            } else if(res.status === 429) {
                this.triggerFailAlert(res.message);
            } else if(res.status === 400) {
                this.triggerFailAlert(res.message);
            } else if(res.status === 401) {
                this.triggerFailAlert(res.message);
            }
        },
        triggerSuccessAlert(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-success',
                duration: 3500,
            })
        },
        triggerFailAlert(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-danger',
                duration: 3500,
            })
        },
        //converting the timeformat from firebase to human readable
        convertTime() {
            for(let i=0; i<this.savedReminder.length; i++) {
                if(this.savedReminder[i].data.times){
                    for(let j=0; j<this.savedReminder[i].data.times.length; j++){
                        var date = new Date(1970, 0, 1); // Epoch
                        date.setSeconds(this.savedReminder[i].data.times[j].seconds)
                        const minutes = date.getMinutes() == 0 ? "00" : date.getMinutes();
                        this.savedReminder[i].data.times[j] = `${date.getHours()}:${minutes}`;
                    }
                }
            }   
        }
    },
    computed: {
        ...mapState({
            showMenu: state => state.showSquareMenu,
            currentFertilizer: state => state.currentFertilizer,
            currentType: state => state.currentPlantType,
            currentSpec: state => state.currentPlantSpec,
            currentId: state => state.currentSquareId,
        }),
        //Reminders in english and german
        reminders: function() {
            switch(this.$i18n.locale){
                case "en":
                    return remindersList.en.amounts;
                case "de":
                    return remindersList.de.amounts;
                default:
                    return remindersList.en.amounts;
            }
        },
        intervals: function() {
            switch(this.$i18n.locale){
                case "en":
                    return remindersList.en.intervals;
                case "de":
                    return remindersList.de.intervals;
                default:
                    return remindersList.en.intervals;
            }
        },
        //plantlists
        herbs: function() {
            switch(this.$i18n.locale){
                case "en":
                    return herbList.en.herbs;
                case "de":
                    return herbList.de.herbs;
                default:
                    return herbList.en.herbs;
            }
        },
        fruits: function() {
            switch(this.$i18n.locale){
                case "en":
                    return fruitList.en.fruits;
                case "de":
                    return fruitList.de.fruits;
                default:
                    return fruitList.en.fruits;
            }
        },
        flowers: function() {
            switch(this.$i18n.locale){
                case "en":
                    return flowerList.en.flowers;
                case "de":
                    return flowerList.de.flowers;
                default:
                    return flowerList.en.flowers;
            }
        },
        bushes: function() {
            switch(this.$i18n.locale){
                case "en":
                    return bushList.en.bushes;
                case "de":
                    return bushList.de.bushes;
                default:
                    return bushList.en.bushes;
            }
        },
        succulents: function() {
            switch(this.$i18n.locale){
                case "en":
                    return succulentList.en.succulents;
                case "de":
                    return succulentList.de.succulents;
                default:
                    return succulentList.en.succulents;
            }
        },
        trees: function() {
            switch(this.$i18n.locale){
                case "en":
                    return treeList.en.trees;
                case "de":
                    return treeList.de.trees;
                default:
                    return treeList.en.trees;
            }
        },
        vegetables: function() {
            switch(this.$i18n.locale){
                case "en":
                    return vegetableList.en.vegetables;
                case "de":
                    return vegetableList.de.vegetables;
                default:
                    return vegetableList.en.vegetables;
            }
        },
        reminderForField: function() {
            if(this.savedReminder && this.currentId){
                return this.savedReminder.filter(reminder => reminder.data.parentId == this.currentId)
            } else {
                return null;
            }
        }
    },
    async created() {
        //getting all fertilizers
        this.fertilizers = await getAllFertilizer();
        this.savedReminder = await getRemindersForUser(this.currentId);
        this.convertTime();
        //calling the wikipedia API
        //const data = await this.fetchWiki();
        //console.log(data);
    }
};
/* TODO: Das mit dem Modal will irgendwie noch nicht so richtig
    const ModalForm = {
        props: ['fertilizerName', 'fertilizerType', 'canCancel'],
        template: `
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add Fertilizer</p>
                        <button
                            type="button"
                            class="delete"
                            @click="$emit('close')"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Name">
                            <b-input
                                type="text"
                                :value="fertilizerName"
                                placeholder="Name..."
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Password">
                            <b-select placeholder="Select a type">
                                <option
                                    v-for="fertilizer in fertilizerOptions"
                                    :value="fertilizer.name"
                                    :key="fertilizer.name">
                                    {{ fertlizer.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="$emit('close')" />
                        <b-button
                            label="Add"
                            type="is-primary" />
                    </footer>
                </div>
            </form>
        `
    }
*/
</script>

<style scoped>
h2.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 16px;
}

h2.heading span {
    font-weight: bold;
}

.bold {
    font-weight: 500;
}

.mar {
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.p-1 {
    padding: 1em;
}

.m-1 {
    margin: 1em;
}

.p-2 {
    margin: 8px 4em;
}

.p-3 {
    margin-top: 20%;
}

.mt {
    margin-top: 5px;
}

.mid {
    margin: auto auto;
}

.icon {
    background: url("/src/assets/guenger.svg");
    height: 20px;
    width: 20px;
    display: block;
}

.center {
    margin: 5px auto;
}

.center-only {
    margin: 2px auto;
    text-align: center;
}

.bolder {
    font-weight: bolder;
}

.center {
    text-align: center;
    margin-top: 15%;    
}

.icn {
    margin-top: 10px;
    margin-left: 5px;
}

.block {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.sm-block {
    display: grid;
    grid-template-columns: 5fr 1fr;
    justify-items: center;
    align-items: center;
}

.hide {
    display: none;
}

</style>