<template>
    <div class="square" @click="changeType" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack" :style="styles">
            <div class="block">
                <div class="squareTitle">
                    <div v-if="specification != '' ">
                        <h3 class="specification">{{ specification }}</h3>
                    </div>
                    <div v-else>
                        <h3 class="placeholder"></h3>
                    </div>
                </div>
                <div class="pic">
                    <img :src="imageSrc" alt="Fields" class="image">
                    <!--
                    <b-image
                        :src="require('@/assets/fields.jpg')"
                        alt="Fields"
                        :rounded="rounded"
                        class="image"
                    >
                    </b-image>-->
                </div>
            </div>
            <div class="block-info">
                <div class="info">
                    <div v-if="fertilizer != ''">
                        <p class="">{{ $t("garden.gardenTile.fertilizer")}}:</p>
                        <p class="no-overflow">{{fertilizer}}</p>
                    </div>
                    <div v-else>
                        <p>{{ $t("garden.gardenTile.noFertilizer")}}</p>
                        <div class="placeholder-fertilizer"></div>
                    </div>
                </div>
                <div class="settings">
                <!-- Tool Tip 
                <b-tooltip label="Menu" position="is-right"> -->
                    <button class="button is-info is-inverted is-rounded" @click="toggle_menu"><!-- <i class="fas fa-tasks"></i> --><i class="fas fa-book"></i></button>
                    <!--
                    <b-button
                        type="is-light"
                        outlined
                        @click="toggle_menu"
                        class="p-1"
                    >
                        <b-icon
                            pack="fas"
                            icon="book"
                            size="is-medium"
                            type="is-success"
                            class="icon center"
                        >
                        </b-icon>
                    </b-button>-->
                <!-- </b-tooltip> -->
                </div>
            </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { updateSquareType } from "../../db/dbMethods";
import db from "../../db/init.js";

export default {
    name: "Square",
    data() {
        return{
            rounded: true,
            styles: {
                backgroundColor: "lightgrey",
            },
            specification: "",
            fertilizer: "",
            mutableType: "",
        }
    },
    props: {
        //fertilizer: String,
        id: String,
        //specification: String,
        type: String,
    },
    methods: {
        //Methods to change the UI
        changeType(e) {
            const color = this.getColor(this.mutableType);
            /*The element can be changed and saved if:
             *  1. The Element clicked on is not the bookicon or the button
             *  2. The Element is not the picture
             *  3. the panelColor is not empty and the panelColor not the same as the color the square already has -> would be the same as deleting it
            */
            if(e.srcElement.nodeName !== "I" && e.srcElement.nodeName !== "BUTTON" && e.srcElement.nodeName !== "IMG" && color != this.panelColor && this.panelColor){
                this.styles.backgroundColor = this.panelColor;
                this.saveSquareType(this.$data.styles.backgroundColor);
                this.mutableType = this.getType(this.$data.styles.backgroundColor);
            }
        },
        /*I don't need this anymore
        getCurrentColor(e) {
            let target;
            console.log(e.srcElement.nodeName);
            //image wurde angeklickt
            if(e.srcElement.nodeName === "IMG"){
                target = e.target.parentElement.parentElement;
                console.log(target);
                return {
                    color: target.attributes.style.value.split(":")[1].trim().slice(0,-1),
                    target
                };
            } 
            //zwischen den Containern wurde geklickt
            else if(e.srcElement.nodeName === "FIGURE") 
            {
                target = e.target.parentElement;
                console.log(target);
                return {
                    color: target.attributes.style.value.split(":")[1].trim().slice(0,-1),
                    target
                };
            }
            else if(e.srcElement.nodeName === "I")
            {
                //no colorchange, but opening the menu
                console.log(target);
                return e.srcElement.nodeName;
            }
            //Container got clicked
            else 
            {
                target = e.target;
                console.log(target);
                if(target.attributes.style.value !== ""){
                    return {
                        color: target.attributes.style.value.split(":")[1].trim().slice(0,-1),
                        target
                    };
                } else {
                    return {
                        color: null,
                        target: null
                    }
                }
            }
        },
        */
        //saving all squares for the garden
        saveSquareType(color){
            updateSquareType(this.id, this.getType(color));
        },
        getType(color){
            switch(color) {
            case "red":
                return "fruit";
            case "green":
                return "herb";
            case "lightskyblue":
                return "flower";
            case "blue":
                return "tree";
            case "purple":
                return "vegetable";
            case "lightpink":
                return "succulent";
            case "greenyellow":
                return "bush";
            case "lightgrey":
                return "";
            }
        },
        getColor(type){
            switch(type) {
            case "fruit":
                return "red";
            case "herb":
                return "green";
            case "flower":
                return "lightskyblue";
            case "tree":
                return "blue";
            case "vegetable":
                return "purple";
            case "succulent":
                return "lightpink";
            case "bush":
                return "greenyellow";
            case "":
                return "lightgrey";
            }
        },
        /*
        Methods for the UI
        */
        toggle_menu(){
            this.toggle_square_menu({id: this.id, type: this.mutableType, specification: this.specification, fertilizer: this.fertilizer});
        },
        changeMouseType() {
            document.body.style.cursor = "pointer";
         },
         changeMouseTypeBack() {
            document.body.style.cursor = "default";
         },
         /*
         Store mapping
         */
        ...mapMutations({
            delete_color: "DELETE_PANELCOLOR",
            set_color: "SET_PANELCOLOR",
            toggle_square_menu: "SHOW_SQUARE_MENU",
        }),
    },
    computed: {
        ...mapState({
            panelColor: state => state.panelColor,
            plantType: state => state.chosenPlantType,
            currentFertilizer: state => state.currentFertilizer,
            currentType: state => state.currentPlantType,
            currentSpec: state => state.currentPlantSpec,
            currentId: state => state.currentSquareId,
        }),
        imageSrc: function() {
            switch(this.mutableType){
                case "":
                    return require('@/assets/fields.jpg');
                case "fruit":
                    return require('@/assets/garden/fruitPicture_resize.jpg');
                case "herb":
                    return require('@/assets/garden/herbPicture_resize.jpg');
                case "flower": 
                    return require('@/assets/garden/flowerPicture_resize.jpg');
                case "tree":
                    return require('@/assets/garden/treePicture_resize.jpg');
                case "vegetable":
                    return require('@/assets/garden/carrotPicture_resize.jpg');
                case "succulent":
                    return require('@/assets/garden/succulentPicture1_resize.jpg');
                case "bush":
                    return require('@/assets/garden/bushPicture_resize.jpg');    
            }
        }
    },
    created() {
        //so that the prop is not changed directly but only the data property is changed
        this.mutableType = this.type;
        //porting colors to squares
        switch(this.mutableType) {
            case "fruit":
                this.$data.styles.backgroundColor="red";
                break;
            case "herb":
                this.$data.styles.backgroundColor="green";
                break;
            case "flower":
                this.$data.styles.backgroundColor="lightskyblue";
                break;
               case "tree":
                this.$data.styles.backgroundColor="blue";
                break;
            case "vegetable":
                this.$data.styles.backgroundColor="purple";
                break;
            case "succulent":
                   this.$data.styles.backgroundColor="lightpink";
                break;
            case "bush":
                this.$data.styles.backgroundColor="greenyellow";
                 break;
            case "":
                this.$data.styles.backgroundColor="lightgrey";
                break;
        };
        //Snapshot also gets the current fertilizer
        db.collection("Square").onSnapshot((snapshot) => {
            snapshot.docChanges().forEach(change => {
                if(change.type === "added"){
                    if(change.doc.id === this.id){
                        //need fertilizer and specification when first loading data
                        this.specification = change.doc.data().specification;
                        this.fertilizer = change.doc.data().fertilizer;
                    } 
                }
                if(change.type === "modified"){
                    if(change.doc.id === this.id){
                        //changing the type and updating with the db
                        if(change.doc.data().type != this.mutableType){
                            this.mutableType = change.doc.data().type;
                            this.specification = "";
                        }
                        //need fertilizer and/or specification when updated
                        this.specification = change.doc.data().specification;
                        this.fertilizer = change.doc.data().fertilizer;
                    }
                }
            })
        });
    }
}
</script>

<style scoped>

.square {
    /*border: 1px solid rgb(1, 82, 18);*/
    text-align: center;
    border-radius: 5px;
    /*width: 15vw;
    height: 15vw;*/
    /*padding: 5px 3px;*/
    margin: 0 auto;
    box-shadow: 1px 2px rgb(1, 82, 18);
}

.squareTitle {
    overflow:hidden;
    width: 15ch;
    font-size: 1.2em;
    font-weight: 520;
    margin: auto auto;
    padding-right: 5px;
}

.image {
    align-content: center;
    width: 150px;
    height: 150px;
    align-self: center;
    margin: 0 auto;
    border-radius: 50px;
}

.pic {
    display: contents;
    margin: 0 auto;
}

.icon {
    margin: auto auto; 
}

.p-1 {
    margin-top: 5px;
    width: 35px;
    height: 39px;
}

.specification {
    overflow:hidden;
    font-weight: bolder;
    font-size: 0.8em;
    color: black;
    margin-bottom: 4px;
}

.placeholder {
    padding:0.8em 0;
    margin-bottom: 4px;
}

.placeholder-fertilizer {
    padding:0.8em 0;
}

.info {
    margin-left: 5px;
    font-size: 0.8em;
    font-weight: 400;
    padding-bottom: 5px;
}

.no-overflow {
    overflow: hidden;
}

@media screen and (min-width: 1500px){
    .specification {
        font-size: 1em;
        font-weight: bold;
    }
    .placeholder {
        padding: 1em 0;
    }
    .block {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (min-width: 790px){
    .block-info {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (min-width: 730px){
    .info {
        padding-bottom: 0;
        text-align: left;
        margin-left: 10px;
        font-size: 1.2em;
        color: black;
        font-weight: 450;
    }
}

@media screen and (min-width: 350px) {
    .squareTitle {
        font-size: 1.6em;
        font-weight: 400;
        margin: auto auto;
        padding-right: 5px;
    }
}

/*max-width media querys */

@media screen and (max-width: 790px){
    /* making the image smaller*/
    .image {
        align-content: center;
        width: 100px;
        height: 100px;
        align-self: center;
        margin: 0 auto;
        border-radius: 50px;
    }
    .squareTitle {
        width: 9ch;
    }
}

@media screen and (max-width: 535px){
    .squareTitle {
        font-size: 1.2em;
        margin: auto auto;
        padding-right: 0px;
        text-align: center;
    }
    .specification {
        overflow:visible;
    }
    .placeholder-fertilizer {
        padding: 0;
    }
}

/* Square Title needs a specific length otherwise Squares get cropped af */

@media screen and (max-width: 440px){
    .squareTitle {
        width: 8ch;
    }
}

@media screen and (max-width: 410px){
    .squareTitle {
        width: 7ch;
    }
}

@media screen and (max-width: 380px){
    .squareTitle {
        width: 6ch;
    }
}

@media screen and (max-width: 350px){
    .squareTitle {
        width: 5ch;
    }
}

@media screen and (max-width: 320px){
    .squareTitle {
        width: 4.5ch;
    }
    .squareTitle {
        overflow:visible;
        width: 100%;
        font-size: 1em;
        font-weight: 430;
        margin: auto auto;
        text-align: center;
    }
    .specification {
        font-weight: bolder;
        font-weight: 500;
        margin: 0px;
    }
}

/* support for very small screens */
@media screen and (max-width: 310px) {
    .button {
        padding: 0 15px;
    }
    .image {
        align-content: center;
        width: 50px;
        height: 100px;
        align-self: center;
        margin: 0 auto;
        border-radius: 15px;
    }
}

</style>