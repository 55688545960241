<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Gardenreminder</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Erinnerungsname">
          <input
            class="input is-success"
            type="text"
            v-model="title"
            placeholder="z.B. Wässern"
            required
          />
        </b-field>
        <label class="label">{{
          $t("garden.menu.reminder.reminderType")
        }}</label>
        <b-select
          v-model="reminderType"
          name="amount"
          expanded
          id="reminderType"
          size="is-normal is-success"
        >
          <option value="daily">
            {{ $t("garden.menu.reminder.reminderTypeOptions.daily") }}
          </option>
          <option value="weekly">
            {{ $t("garden.menu.reminder.reminderTypeOptions.weekly") }}
          </option>
          <option value="monthly">
            {{ $t("garden.menu.reminder.reminderTypeOptions.monthly") }}
          </option>
        </b-select>
        <div v-if="reminderType == 'daily'" class="p-1 center-only">
          <Timepicker />
        </div>
        <div v-else-if="reminderType == 'weekly'">
          <p class="p-1">{{ $t("garden.menu.reminder.remindAmount") }}</p>
          <b-field :label="`${$t('garden.menu.reminder.amountPlaceholder')}`">
            <b-numberinput
              controls-alignment="left"
              size="is-small"
              type="is-success"
              v-model="reminderAmountWeekly"
            ></b-numberinput>
          </b-field>
        </div>
        <div v-else-if="reminderType == 'monthly'">
          <p class="p-1">{{ $t("garden.menu.reminder.remindAmount") }}</p>
          <b-field :label="`${$t('garden.menu.reminder.amountPlaceholder')}`">
            <b-numberinput
              controls-alignment="left"
              size="is-small"
              type="is-success"
              v-model="reminderAmountMonthly"
            ></b-numberinput>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click.prevent="$emit('close')">
          Close
        </button>
        <button class="button is-success" @click.prevent="checkGardenreminder">
          Speichern
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import Timepicker from "../Form/Timepicker.vue";
import { addReminder } from "../../db/reminder.js";
import { sendReminderToServer } from "../../db/serverDB.js";
export default {
  name: "GardenreminderForm",
  data() {
    return {
      reminderType: "",
      title: "",
      reminderAmountWeekly: 1,
      reminderAmountMonthly: 1,
    };
  },
  components: {
    Timepicker,
  },
  methods: {
    async checkGardenreminder() {
      if (this.reminderType === "daily") {
        //calling the event
        this.$store.dispatch("reminder/change_props", true);
        //--> die Daten werden in store gespeichert und der watcher hier übernimmt den Rest
      } else {
        await this.saveGardenReminder();
      }
    },
    async saveGardenReminder() {
      if (this.title) {
        if (this.reminderType === "daily") {
          const reminder = {
            title: this.title,
            garden: true,
            type: this.reminderType,
            times: this.times,
            parentId: this.$route.params.id,
            userId: this.$store.state.user.authStatus.uid,
          };
          try {
            //Saving reminder on firebase storage
            addReminder(reminder);
            this.triggerSuccessAlert("You added a reminder 📅");
            this.title = "";
            this.reminderType = "";
            this.reminderAmountWeekly = 1;
            this.reminderAmountMonthly = 1;
            //resetting times in store so to process can be done again
            this.$store.dispatch("reminder/change_times", []);
            //closing the modal
            this.$emit("close");
            //Saving reminder on squaregarden backend
            const res = await sendReminderToServer(reminder);
            this.checkRes(res);
          } catch (err) {
            console.error("Error sending reminder to Server!");
          }
        } else if (this.reminderType === "weekly") {
          if (this.reminderAmountWeekly) {
            //reminder values
            const reminder = {
              title: this.title,
              garden: true,
              type: this.reminderType,
              amount: this.reminderAmountWeekly,
              interval: this.reminderType,
              parentId: this.$route.params.id,
              userId: this.$store.state.user.authStatus.uid,
            };
            try {
              //Saving reminder on firebase storage
              addReminder(reminder);
              this.triggerSuccessAlert("You added a reminder 📅");
              this.title = "";
              this.reminderType = "";
              this.reminderAmountWeekly = 1;
              this.reminderAmountMonthly = 1;
              //resetting times in store so to process can be done again
              this.$store.dispatch("reminder/change_times", []);
              //closing the modal
              this.$emit("close");
              //Saving reminder on squaregarden backend
              const res = await sendReminderToServer(reminder);
              this.checkRes(res);
            } catch (err) {
              console.error("Error sending reminder to Server!");
            }
          } else {
            this.triggerFailAlert(
              "You need to select an amout and an Interval!"
            );
          }
        } else if (this.reminderType === "monthly") {
          if (this.reminderAmountMonthly) {
            //reminder values
            const reminder = {
              title: this.title,
              garden: false,
              type: this.reminderType,
              amount: this.reminderAmountMonthly,
              interval: this.reminderType,
              parentId: this.$route.params.id,
              userId: this.$store.state.user.authStatus.uid,
            };
            try {
              addReminder(reminder);
              this.triggerSuccessAlert("You added a reminder 📅");
              this.title = "";
              this.reminderType = "";
              this.reminderAmountWeekly = 1;
              this.reminderAmountMonthly = 1;
              //resetting times in store so to process can be done again
              this.$store.dispatch("reminder/change_times", []);
              //closing the modal
              this.$emit("close");
              //Saving reminder on squaregarden backend
              const res = await sendReminderToServer(reminder);
              this.checkRes(res);
            } catch (err) {
              console.error("Error sending reminder to Server!");
            }
          } else {
            this.triggerFailAlert(
              "You need to select an amout and an Interval!"
            );
          }
        } else {
          this.triggerFailAlert("You need to specify a remindertype!");
        }
      } else {
        this.triggerFailAlert("You need to specify a Reminder title!");
      }
    },
    triggerSuccessAlert(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success",
        duration: 3500,
      });
    },
    triggerFailAlert(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger",
        duration: 3500,
      });
    },
    checkRes(res) {
      if (res.status === 200) {
        this.triggerSuccessAlert(res.message);
      } else if (res.status === 429) {
        this.triggerFailAlert(res.message);
      } else if (res.status === 400) {
        this.triggerFailAlert(res.message);
      } else if (res.status === 401) {
        this.triggerFailAlert(res.message);
      }
    },
  },
  computed: {
    times() {
      return this.$store.state.reminder.times;
    },
  },
  watch: {
    times(val) {
      if (val.length != 0) {
        //save timedata
        this.saveGardenReminder();
      }
    },
  },
};
</script>

<style>
</style>