/* ALl the reminder db stuff */

import db from "./init.js"
import store from "../store/index.js";

//creates a reminders
export function addReminder(reminder) {
    db.collection("Reminders").add(reminder)
    .then(() => {
        console.log("Reminder sucessfully added!");
    })
    .catch(err => {
        console.log(err);
    })
}

//Return all reminders created by a User
export async function getRemindersForUser(){
    const remindersDB = await db.collection("Reminders").where("userId", "==", store.state.user.authStatus.uid).get();
    let reminders = [];
    remindersDB.docs.forEach(reminder => reminders.push({id: reminder.id, data: reminder.data()}));
    return reminders;
}

export function getReminderForGarden() {

}

//delete a reminder by id
export async function deleteReminderById(id){
    try {
        const res = await db.collection("Reminders").doc(id).delete()
        return {status: 0, message: "Reminder successfully deleted" }
    } catch(err) {
        return {status: 1, message: err};
    }
}