<template>
  <div>
    <div v-for="(time, index) in times" :key="index">
      <div class="block">
        <b-timepicker
          v-model="times[index]"
          inline
          class="mt"
          :incrementMinutes="minutesGranularity"
          size="is-small"
        ></b-timepicker>
        <b-button
          @click.prevent="delTime(index)"
          type="is-danger is-small"
          class="mid-horizontal"
          ><i class="fas fa-minus"></i
        ></b-button>
      </div>
    </div>
    <div class="has-text-centered mt-2">
      <button
        @click.prevent="addTime"
        class="button is-success is-small mid-vert mt"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timepicker",
  data() {
    return {
      times: [new Date()],
      minutesGranularity: 15,
    };
  },
  props: {},
  methods: {
    addTime() {
      this.times.push(new Date());
    },
    delTime(index) {
      this.times.splice(index, 1);
    },
  },
  computed: {
    saveProps() {
      return this.$store.state.reminder.saveProps;
    },
  },
  watch: {
    saveProps(val) {
      if (val) {
        //saving the times property to the vuex-store
        this.$store.dispatch("reminder/change_times", this.times);
        this.$store.dispatch("reminder/change_props", false);
      }
    },
  },
};
</script>

<style scoped>
.block {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.mt {
  margin-top: 5px;
}

.mt-2 {
  padding-top: 20px;
}

.mid-horizontal {
  margin-top: 20px;
}
</style>