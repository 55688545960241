<template class="has-navbar-fixed-bottom">
  <div>
    <NavComp />
    <SquareMenu />
    <div class="container">
      <div class="top-menu">
        <!--<b-button @click="back" class="push-down" type="is-warning is-light">Back</b-button>-->
        <div class="card2">
          <p class="gardenTitle">
            {{ $t("garden.infoCard.title") }}: {{ garden.data.name }}
          </p>
          <p class="garden-info">
            {{ $t("garden.infoCard.dimension") }}: {{ garden.data.length }} x
            {{ garden.data.width }}
          </p>
          <p class="garden-info">
            {{ $t("garden.infoCard.length") }}: {{ garden.data.division }}
          </p>
        </div>
        <div class="buttons is-centered">
          <button @click="endSeason" class="button is-danger">
            {{ $t("garden.seasonEnd.button") }}
          </button>
          <Gardenreminder />
        </div>
      </div>
      <div class="board">
        <Board
          :_squares="squares"
          :_length="garden.data.length"
          :_width="garden.data.width"
          :_division="garden.data.division"
          :_id="garden.id"
          :key="rerenderKey"
        />
      </div>
    </div>
    <OptionsPanel />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavComp from "@/components/NavComp.vue";
import Board from "../components/Board/Board.vue";
import OptionsPanel from "../components/Board/OptionsPanel.vue";
import {
  getGardenById,
  createSquares,
  updateGardencreatedSquare,
  getAllSquaresForGarden,
  createSeasonEnding,
} from "../db/dbMethods";
import SquareMenu from "../components/Board/SquareMenu.vue";
import Gardenreminder from "@/components/Reminder/Gardenreminder.vue";

export default {
  name: "Garden",
  data() {
    return {
      id: this.$route.params.id,
      garden: {
        id: null,
        data: {
          name: null,
          width: null,
          length: null,
          division: null,
        },
      },
      squares: [],
      rerenderKey: 0,
    };
  },
  components: {
    Board,
    OptionsPanel,
    SquareMenu,
    NavComp,
    Gardenreminder,
  },
  methods: {
    back() {
      this.$router.push({ name: "Dashboard" });
    },
    endSeason() {
      this.$buefy.dialog.prompt({
        message: `How high was your Yield?`,
        inputAttrs: {
          type: "number",
          placeholder: "Your Yield in KG",
          value: "",
          maxlength: 20,
        },
        trapFocus: true,
        onConfirm: async (value) => {
          const allFertilizers = this.getAllFertilizers();
          await createSeasonEnding(
            {
              amount: parseInt(value),
              date: Date(Date.now()),
              gardenTitle: this.currentGardenTitle,
              fertilizers: allFertilizers,
              userId: this.$store.state.user.authStatus.uid,
            },
            this.$route.params.id
          );
          this.$buefy.toast.open(`Field is closed with ${value} kg!`);
          this.$router.push(`/dashboard`);
        },
      });
    },
    fetchGarden() {
      getGardenById(this.$route.params.id)
        .then(async (gardendb) => {
          if (!gardendb.data.createdSquares) {
            await createSquares(gardendb);
            gardendb.data.createdSquares = true;
            await updateGardencreatedSquare(gardendb);
          }
          this.garden = gardendb;
          this.rerenderKey += 1;
        })
        .catch((err) => console.log("Error loading Garden: ", err));
    },
    fetchSquares() {
      getAllSquaresForGarden(this.$route.params.id)
        .then((squaresFromDB) => {
          //sorting squares by their number
          squaresFromDB.sort((s1, s2) => {
            return s1.data.number - s2.data.number;
          });
          this.squares = squaresFromDB;
          this.rerenderKey += 1;
        })
        .catch((err) => {
          console.log("Fetching squares unuccessful! ", err);
        });
    },
    //getting all fertilzers for a field and saving them with the seaon ending
    getAllFertilizers() {
      let fertilizerArray = [];
      this.squares.forEach((square) => {
        //only push if fertilzer is not in the Array
        if (
          !fertilizerArray.includes(square.data.fertilizer) &&
          square.data.fertilizer != ""
        ) {
          fertilizerArray.push(square.data.fertilizer);
        }
      });
      return fertilizerArray;
    },
  },
  async created() {
    this.fetchGarden();
    this.fetchSquares();
  },
  computed: {
    ...mapState({
      currentGardenTitle: (state) => state.currentGardenTitle,
      gardens: (state) => state.gardens,
      showMenu: (state) => state.showSquareMenu,
    }),
  },
};
</script>

<style >
.board {
  padding-bottom: 10em;
}

.card2 {
  text-align: center;
  margin-bottom: 0.7em;
  margin-right: auto;
  margin-top: 5px;
  background-color: #94c973;
}

.gardenTitle {
  font-size: 1.6rem;
  font-weight: bold;
}

.garden-info {
  font-size: 1.1em;
}

.align-center {
  margin: 0 auto;
}

.buttons {
  padding-bottom: 10px;
}

@media screen and (min-width: 350px) {
  .board {
    margin: 0 10px;
  }
}

@media screen and (max-width: 311px) {
  .garden-info {
    font-size: 0.8em;
  }
}
</style>