<template>
    <div class="square-container">
        <div v-for="(elem, index) in split_array" :key="index">
            <div v-for="square in split_array[index]" :key="square.id" class="column">
                <Square :fertilizer="square.data.fertilizer" :id="square.id" :specification="square.data.specification" :type="square.data.type" class="column"/>
            </div>
        </div>
    </div>
</template>

<script>
import Square from './Square.vue';

export default {
    name: "Board",
    data() {
        return {
        }
    },
    components: {
        Square
    },
    methods: {
    },
    props: {
        _length: Number,
        _width: Number,
        _division: Number,
        _id: String,
        _squares: Array,
    },
    computed: {
        total_squares: function() {
            return (this._length/this._division) * (this._width/this._division);
        },
        number_squares_per_column: function() {
            return this._length / this._division
        },
        split_array: function() {
            let arr = [];
            let temp = [];
            //squares are undefined
            if(!this._squares[0]){
                return [];
            }
            for(let i=0; i<this.total_squares; i++){
                if(i%this.number_squares_per_column === 0 && i != 0){
                    arr.push(temp);
                    temp = [];
                    temp[0] = this._squares[i];
                } else {
                    temp.push(this._squares[i]);
                }
            }
            arr.push(temp);
            return arr;
        }
    }
}
</script>

<style scoped>
.square-container {
    display: table; 
                    /*
    border-collapse: separate;  
                    */ 
    border-collapse: separate; 
    border-spacing: 0px 0px;
    border-width: thin;
    background: #94C973;
    margin: 0 auto;
}

.row {
    display: table-column-group;
}

.column {
    display: table-cell;
}

</style>