<template>
    <nav class="nav navbar is-success is-fixed-bottom" role="navigation" aria-label="main navigation">
        <!--<div class="container"> -->
            <div class="navbar-brand cont">
                <div class="block">
                    <span class="pair">
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color1" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.fruits") }}{{ fruitlabel }}</p>
                            </div>
                        </div>
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color2" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.herbs") }}{{ herblabel }}</p>
                            </div>
                        </div>
                    </span>
                    <span class="pair">
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color3" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.flowers") }}{{ flowerlabel }}</p>
                            </div>
                        </div>
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color4" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.trees") }}{{ treelabel }}</p>
                            </div>
                        </div>
                    </span>
                    <span class="pair">
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color5" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.vegetables") }}{{ vegetablelabel }}</p>
                            </div>
                        </div>
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color6" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.succulents") }}{{ succulentlabel }}</p>
                            </div>
                        </div>
                    </span>
                    <span class="pair">
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color7" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.bushes") }}{{ bushlabel }}</p>
                            </div>
                        </div>
                        <div class="navbar-item square" @click="getColor">
                            <div class="element">
                                <div class="color-class" :style="colors.color8" @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack"></div>
                                <p @mouseover="changeMouseType" @mouseleave="changeMouseTypeBack">{{ $t("garden.typeMenu.delete") }}{{ deletelabel }}</p>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        <!-- </div> -->
    </nav>
</template>

<script>
import { mapState, mapMutations } from "vuex"

export default {
    name: "OptionsPanel",
    data() {
        return {

            colors: {
                color1: {
                    backgroundColor: "red",
                },
                color2: {
                    backgroundColor:"green",
                },
                color3: {
                    backgroundColor:"lightskyblue",
                },
                color4: {
                    backgroundColor:"blue",
                },
                color5: {
                    backgroundColor:"purple",
                },
                color6: {
                    backgroundColor:"lightpink",
                },
                color7: {
                    backgroundColor:"greenyellow",
                },
                color8: {
                    backgroundColor:"lightgrey",
                },
            },
        }
    },
    methods: {
        getColor(e) {
            /*
            schau um welches div element es sich handelt
            Wenn P => ParentElement davon DIV child Color
            Wenn DIV => Wenn childlength == 0 => Richtiges Element bereits 
            Wenn DIV => Wenn childlength != 0 => Childelement das DIV ist
            */
           let color = this.set_color;
           let target = null;
            if(e.srcElement.nodeName === "P") {
                color = e.target.parentElement.firstElementChild.attributes.style.value.split(":")[1].trim().slice(0,-1);
                target = e.target.parentElement.firstElementChild;
            } else {
                if(e.target.children.length == 0){
                    color = e.target.attributes.style.value.split(":")[1].trim().slice(0,-1);
                    target = e.target;
                } else {
                    //Hier möchte ich evtl nichts machen -> da kein Buttonhintergrund
                    //console.log(e.target.firstElementChild.attributes.style.value.split(":")[1].trim());
                }
            }
            //if you click on the same color -> delete it (no color anymore) and remove borders
            if(this.panelColor == color){
                this.set_color(null);
                this.deleteStyle(target.parentElement);
            } else {
                this.set_color(color);
                //if color is set, style this color seperatly
                if(target){
                    this.styleColor(target.parentElement);
                }
            }
        },
        styleColor(element) {
            this.deleteBordersForElement();
            element.style.borderWidth = "3px";
            element.style.border = "dashed green";
            element.style.borderRadius = "10%"
            element.style.background = "lightgreen";
            element.style.color = "black";
            element.style.fontWeight = "bold";
        },
        deleteStyle(element){
            this.deleteBordersForElement();
        },
        deleteBordersForElement(){
            const elements = document.querySelectorAll(".element");
            elements.forEach(element => {
                if(element.attributes.style){
                    element.attributes.style.value = "";
                }
            })
        },
        changeMouseType() {
           document.body.style.cursor = "pointer";
        },
        changeMouseTypeBack() {
           document.body.style.cursor = "default";
        },
        ...mapMutations({
          delete_color: "DELETE_PANELCOLOR",
          set_color: "SET_PANELCOLOR",
        })
    },
    components: {
    },
    computed: {
        ...mapState({
            panelColor: state => state.panelColor,
        }),
        /* Media Querys, that change the navbar */
        fruitlabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
                return "";
            //} else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🍓";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Fruits";
            } else {
                return "🍓";
            }
        },
        herblabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
                return "";
            //} else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🌿";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Herbs";
            } else {
                return "🌿";
            }
        },    
        flowerlabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
                return "";
            //} else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🌺";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Flowers";
            } else {
                return "🌺";
            }
        }, 
        treelabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
               return "";
            } //else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🌲";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Trees";
            //}
            else 
            {
                return "🌲";
            }
        },  
        vegetablelabel: () => {
            if(window.innerWidth <= 413 || document.body.clientWidth <= 413){
                return "";
            }
            //else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🥕";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Vegetables";
            //} 
            else {
                return "🥕";
            }
        },  
        succulentlabel: () => {
            if(window.innerWidth <= 413 || document.body.clientWidth <= 413){
                return "";
            } 
            //else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🌵";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Succulents";
            //} 
            else {
                return "🌵";
            }
        },  
        bushlabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
                return "";
            //} else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🌳";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Bushes";
            } else {
                return "🌳";
            }
        },  
        deletelabel: () => {
            if(window.innerWidth < 413 || document.body.clientWidth < 413){
                return "";
            //} else if (window.innerWidth < 925 || document.body.clientWidth < 925) {
            //    return "🗑️";
            //} else if (window.innerWidth < 1216 || document.body.clientWidth < 1216){
            //    return "Delete";
            } else {
                return "🗑️";
            }
        }
    }
}



//at 1215px width delete the emojis
/*const width = window.innerWidth || document.body.clientWidth;
            let maxChars;
            //länge des Extracts die wir wollen von der WikipediaAPI
            if(width < 414) maxChars = 65;
            if(width >= 414 && width < 1400) maxChars = 100;
            if(width >= 1400) maxChars = 130;
        return maxChars;*/

</script>

<style scoped>
p{ 
    font-size: 0.8em;
}

.nav {
    background: #94C973;
    width: 100%;
}

.color-class{
    width: 1em;
    height: 1em;
    font-weight: bolder;
    margin: 1px auto;
}

.block {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
}

.pair{
    margin: 0 auto;
}

/* new media querys */
@media screen and (min-width: 550px){
    p {
      font-size: 1em;  
    }
}

@media screen and (min-width: 830px){
    .block {
        display: contents;
    }
    .pair {
        display: contents;
    }
    .square {
        margin: auto auto;
    }
    .cont {
        margin: 0 5rem 0 2rem; 
    }
}


@media screen and (min-width: 1550px){
    .square {
        margin: 0 15px;
    }
}

@media screen and (min-width: 1024px){
    .color-class{
        width: 2em;
        height: 2em;
        font-weight: bolder;
        margin: 2px auto;
    }
    .square {
        font-weight: bolder;
        margin:  0 0.6em;
    }
    .cont {
        margin: 0 auto;
    }
}

</style>