<template>
  <section>
    <button @click="isComponentModalActive = true" class="button is-success">
      Add gardenreminder
    </button>
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Gardenreminder Modal"
      aria-modal
    >
      <template #default="props">
        <GardenreminderForm @close="props.close" />
        <!-- <modal-form v-bind="formProps" @close="props.close"></modal-form> -->
      </template>
    </b-modal>
  </section>
</template>

<script>
import GardenreminderForm from "./GardenreminderForm.vue";
export default {
  name: "Gardenreminder",
  data() {
    return {
      isComponentModalActive: false,
    };
  },
  components: {
    GardenreminderForm,
  },
  methods: {},
};
</script>

<style>
</style>